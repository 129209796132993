.lightsPuzzle {
  caret-color: #0000;
  grid-template-rows: repeat(3, 130px);
  grid-template-columns: repeat(3, 130px);
  padding: 30px;
  display: grid;
}

.lightOnSquare {
  background-color: #4f2a2a;
  justify-content: center;
  align-items: center;
  display: grid;
}

.lightOnSquare:hover {
  background-color: #4f2a2acc;
}

.lightOffSquare {
  background-color: #2a2a2a;
  justify-content: center;
  align-items: center;
  display: grid;
}

.lightOffSquare:hover {
  background-color: #2a2a2acc;
}

/*# sourceMappingURL=index.9d77feaf.css.map */
