/* Puzzle Lights */

.lightsPuzzle {
    caret-color: transparent;
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(3, 130px);
    grid-template-rows: repeat(3, 130px);
}

.lightOnSquare {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgb(79, 42, 42);
}

.lightOnSquare:hover {
    background-color: rgba(79, 42, 42, 0.8);
}

.lightOffSquare {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: rgb(42, 42, 42);
}

.lightOffSquare:hover {
    background-color: rgb(42, 42, 42, 0.8);
}
